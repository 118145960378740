export const pt = {
  "name": "Portuguese",
  "flagCode": "pt",
  "locale": "pt-PT",
  "project": {
    "name": "Registo de Motorista"
  },
  "key": "pt",
  "validations": {
    "PLEASE_SELECT_CITY": "Por favor, selecione a cidade.",
    "PLEASE_SELECT_SERVICE": "Please select service.",
    "PLEASE_SELECT_DRIVER_TYPE": "Por favor, selecione o tipo de motorista.",
    "PLEASE_INPUT_FIRSTNAME": "Por favor, insira seu primeiro nome.",
    "PLEASE_INPUT_LASTNAME": "Por favor, insira seu sobrenome.",
    "PLEASE_INPUT_EMAIL": "Por favor, insira seu e-mail.",
    "INVALID_EMAIL": "Por favor, especifique um e-mail válido.",
    "PLEASE_INPUT_MOBILE_PHONE": "Por favor, insira o número de telefone.",
    "INVALID_PHONE_NUMBER": "Por favor, especifique um número de telefone válido.",
    "PHONE_NUNBER_EXITS": "Número de telefone já em uso com outra conta.",
    "USERNAME_EXITS": "Este nome de utilizador já existe no nosso sistema.",
    "ERROR_INPUT_VALID_USERNAME": "O nome de utilizador só pode conter letras minúsculas e números e deve ter entre 3 e 20 caracteres.",
    "FIELD_IS_REQUIRED": "Este campo é obrigatório.",
    "REFERRAL_CODE_INVALID": "Código de indicação é inválido.",
    "INVALID_USERNAME": "Por favor, especifique um nome de utilizador válido.",
    "FIELD_IS_REQUIRED_BETWEEN_3_TO_20": "O nome de utilizador deve ter entre 3 e 20 caracteres.",
    "PHONE_NUNBER_EXITS_IN_GROUP": ""
  },
  "errors": {
    "3034": "Este telefone já existe no sistema. Por favor, insira outro.",
    "4001": "The License Plate is exits",
    "NETWORK_ERROR": "Erro: Erro de Rede"
  },
  "general": {
    "CONTINUE": "Continuar",
    "STEP_COMPLETE": "{0}/{1} Completo",
    "STEP_HEADER": "Passo {0}",
    "BACK": "Voltar",
    "NEXT": "Próximo",
    "SUBMIT": "Enviar",
    "Clear_Selected_Items": "Limpar itens selecionados",
    "no_results": "Sem resultados",
    "add_new_item": "Adicionar Novo"
  },
  "home": {
    "BECOME": "Torne-se um motorista da %{name}.",
    "HEADING": "Digite as suas informações básicas para começar.",
    "APPLY_NOW": "Inscrever-se",
    "INDIVIDUAL_CAR_GOJO": "Transporte/Táxi - Eu possuo um veículo",
    "INDIVIDUAL_CAR": "Transporte - Eu possuo um veículo",
    "COMPANY_CAR": "Transporte - Veículo da empresa",
    "DELIVERY_INDIVIDUAL_CAR": "Entrega - Eu possuo um veículo",
    "DELIVERY_COMPANY_CAR": "Entrega - Veículo da empresa",
    "PLACEHOVER_SELECT_PRE_CITY": "Cidade",
    "PLACEHOVER_SELECT_REGISTRATION_TYPE": "Selecione o tipo de motorista",
    "USERNAME": "Nome de utilizador ",
    "FIRST_NAME": "Primeiro Nome",
    "LAST_NAME": "Sobrenome",
    "EMAIL": "Email (optional)",
    "PHONE_NUMBER": "Número de Telemóvel",
    "REFERRAL_CODE": "Código de referenciação (opcional)",
    "I_AGREE_TO": "Ao me cadastrar, li e concordei com os",
    "TERMS_OF_USE": "Termos de Serviço",
    "AND": "e",
    "PRIVACY_POLICY": "Política de Privacidade",
    "I_AGREE_TO_AFTER": ".",
    "Company": "Supplier",
  },
  "finish": {
    "FINISH_1": "Obrigado por se inscrever na %{name}!",
    "FINISH_3": "Faça login para começar!",
    "FINISH_USE_PASSWORD_1": "Quase lá!",
    "FINISH_USE_PASSWORD_2": "Para ativar a sua conta e receber serviços, siga estas etapas:",
    "FINISH_USE_PASSWORD_3": "1. Faça login com o seu número de telefone registado",
    "FINISH_USE_PASSWORD_4": "2. Senha: <span style='font-weight: bold'>12345678</span>",
    "FINISH_USE_PASSWORD_5": "3. Complete a sua inscrição",
    "OK": "Faça login",
    "downloadApp": "Baixe agora a aplicação de Motorista <span style='color: %{color}' >%{name}</span> e faça login com seu número de telefone registado."
  },
  "datePlaceHolder": "MM/DD/YYYY"
};