import React from 'react';
// import banner from '../../Constants/images/banner.jpg';
import { Carousel } from 'react-bootstrap';
import './banner.css'

function Banner({ urlsBannerImage, position }) {
    return (
        <div className={`banner ${position}`}>
            <Carousel className={"banner_carousel"}>
                {urlsBannerImage.map(url => {
                    return (
                        <Carousel.Item>
                            <img className="img_banner" src={url} />
                        </Carousel.Item>
                    )
                })}
            </Carousel>
        </div>
    )
}

export default Banner