export const ka = {
  "name": "Georgian",
  "flagCode": "ge",
  "locale": "ka-GE",
  "project": {
    "name": "მძღოლის რეგისტრაცია"
  },
  "key": "ka",
  "validations": {
    "PLEASE_SELECT_CITY": "გთხოვთ, აირჩიოთ ქალაქი.",
    "PLEASE_SELECT_SERVICE": "გთხოვთ, აირჩიოთ სერვისი.",
    "PLEASE_SELECT_DRIVER_TYPE": "გთხოვთ, აირჩიოთ დრაივერის ტიპი.",
    "PLEASE_INPUT_FIRSTNAME": "გთხოვთ შეიყვანოთ თქვენი სახელი.",
    "PLEASE_INPUT_LASTNAME": "გთხოვთ შეიყვანოთ თქვენი გვარი.",
    "PLEASE_INPUT_EMAIL": "გთხოვთ შეიყვანოთ თქვენი ელფოსტა.",
    "INVALID_EMAIL": "გთხოვთ, მიუთითოთ სწორი ელფოსტა.",
    "PLEASE_INPUT_MOBILE_PHONE": "გთხოვთ შეიყვანოთ თქვენი ტელეფონის ნომერი.",
    "INVALID_PHONE_NUMBER": "გთხოვთ, მიუთითოთ სწორი ტელეფონის ნომერი.",
    "PHONE_NUNBER_EXITS": "ტელეფონის ნომერი უკვე გამოიყენება სხვა ანგარიშთან ერთად.",
    "USERNAME_EXITS": "ეს მომხმარებლის სახელი უკვე არსებობს ჩვენს სისტემაში.",
    "ERROR_INPUT_VALID_USERNAME": "მომხმარებლის სახელი შეიძლება შეიცავდეს მხოლოდ ქვედა ასოებსა და ციფრებს და უნდა იყოს 3-დან 20 სიმბოლომდე",
    "FIELD_IS_REQUIRED": "Ეს ველი სავალდებულოა.",
    "REFERRAL_CODE_INVALID": "რეფერალური კოდი არასწორია.",
    "INVALID_USERNAME": "გთხოვთ, მიუთითოთ სწორი მომხმარებლის სახელი.",
    "FIELD_IS_REQUIRED_BETWEEN_3_TO_20": "მომხმარებლის სახელი უნდა იყოს 3-დან 20 სიმბოლომდე.",
    "PHONE_NUNBER_EXITS_IN_GROUP": ""
  },
  "errors": {
    "3034": "ეს ტელეფონი უკვე არსებობდა სისტემაში. გთხოვთ შეიყვანოთ სხვა.",
    "4001": "სანომრე ნიშანი გადის",
    "NETWORK_ERROR": "შეცდომა: ქსელის შეცდომა"
  },
  "general": {
    "CONTINUE": "განაგრძეთ",
    "STEP_COMPLETE": "{0}/{1} დასრულებული",
    "STEP_HEADER": "ნაბიჯი {0}",
    "BACK": "უკან",
    "NEXT": "შემდეგი",
    "SUBMIT": "გაგზავნა",
    "Clear_Selected_Items": "არჩეული ელემენტების გასუფთავება",
    "no_results": "არანაირი შედეგი",
    "add_new_item": "Ახლის დამატება"
  },
  "home": {
    "BECOME": "Become a %{name} Driver.",
    "HEADING": "დასაწყებად შეიყვანეთ თქვენი ძირითადი ინფორმაცია",
    "APPLY_NOW": "დარეგისტრირდით",
    "INDIVIDUAL_CAR_GOJO": "ტრანსპორტი/ტაქსი - ვფლობ მანქანას",
    "INDIVIDUAL_CAR": "ტრანსპორტი - ვფლობ მანქანას",
    "COMPANY_CAR": "ტრანსპორტი - კომპანიის სატრანსპორტო საშუალება",
    "DELIVERY_INDIVIDUAL_CAR": "მიწოდება - ვფლობ მანქანას",
    "DELIVERY_COMPANY_CAR": "მიწოდება - კომპანიის მანქანა",
    "PLACEHOVER_SELECT_PRE_CITY": "ქალაქი",
    "PLACEHOVER_SELECT_REGISTRATION_TYPE": "აირჩიეთ მძღოლის ტიპი",
    "USERNAME": "მომხმარებლის სახელი",
    "FIRST_NAME": "Სახელი",
    "LAST_NAME": "Გვარი",
    "EMAIL": "ელფოსტა (არასავალდებულო)",
    "PHONE_NUMBER": "Მობილურის ნომერი",
    "REFERRAL_CODE": "რეფერალური კოდი (არასავალდებულო)",
    "I_AGREE_TO": "რეგისტრაციისას წავიკითხე და დავთანხმდი",
    "TERMS_OF_USE": "Მომსახურების პირობები",
    "AND": "და",
    "PRIVACY_POLICY": "Კონფიდენციალურობის პოლიტიკა",
    "I_AGREE_TO_AFTER": ".",
    "Company": "Supplier",
  },
  "finish": {
    "FINISH_1": "გმადლობთ %{name}-ით დარეგისტრირებისთვის!",
    "FINISH_3": "დასაწყებად შედით ახლავე!",
    "FINISH_USE_PASSWORD_1": "თითქმის დავასრულეთ!",
    "FINISH_USE_PASSWORD_2": "თქვენი ანგარიშის გასააქტიურებლად და სამუშაოების მისაღებად, გთხოვთ, მიჰყევით ამ ნაბიჯებს:",
    "FINISH_USE_PASSWORD_3": "1. შედით თქვენი რეგისტრირებული ტელეფონის ნომრით",
    "FINISH_USE_PASSWORD_4": "2. პაროლი: <span style='font-weight: bold'>12345678</span>",
    "FINISH_USE_PASSWORD_5": "3. შეავსეთ თქვენი განაცხადი",
    "OK": "Შესვლა",
    "downloadApp": "ჩამოტვირთეთ <span style='color: %{color}' >%{name}</span> დრაივერის აპი ახლა და შედით თქვენი რეგისტრირებული ტელეფონის ნომრით."
  },
  "datePlaceHolder": "MM/DD/YYYY"
};