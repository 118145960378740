
import Axios from 'axios';
import queryString from "query-string";

import { getFleetInfo } from '../Constants/apiConfig';
export const FETCH_FLEET_INFO_REQUEST = 'FETCH_FLEET_INFO_REQUEST';
export const FETCH_FLEET_INFO_SUCCESS = 'FETCH_FLEET_INFO_SUCCESS';
export const FETCH_FLEET_INFO_FAILURE = 'FETCH_FLEET_INFO_FAILURE';


export const fetchFleetInfoRequest = () => {
    return {
        type: FETCH_FLEET_INFO_REQUEST
    }
}

export const fetchFleetInfoSuccess = data => {
    return {
        type: FETCH_FLEET_INFO_SUCCESS,
        payload: data
    }
}

export const fetchFleetInfoFailure = error => {
    return {
        type: FETCH_FLEET_INFO_FAILURE,
        payload: error
    }
}


export const fetchFleetInfo = (params) => {
    let queryParams = queryString.stringify(params);
    return (dispatch) => {
        dispatch(fetchFleetInfoRequest())
        Axios.get(getFleetInfo + queryParams)
            .then(res => {
                const data = res.data.res;
                dispatch(fetchFleetInfoSuccess(data))
            })
            .catch(err => {
                const errMsg = err.message
                dispatch(fetchFleetInfoFailure(errMsg))
            })
    }
}

