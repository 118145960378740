export const ru = {
  "name": "Russian",
  "flagCode": "ru",
  "locale": "ru-RU",
  "project": {
    "name": "Регистрация водителя"
  },
  "key": "ru",
  "validations": {
    "PLEASE_SELECT_CITY": "Пожалуйста, выберите город.",
    "PLEASE_SELECT_SERVICE": "Пожалуйста, выберите вид услуги.",
    "PLEASE_SELECT_DRIVER_TYPE": "Пожалуйста, выберите тип водителя.",
    "PLEASE_INPUT_FIRSTNAME": "Пожалуйста, введите свое имя.",
    "PLEASE_INPUT_LASTNAME": "Пожалуйста, введите свою фамилию.",
    "PLEASE_INPUT_EMAIL": "Пожалуйста, введите свой адрес электронной почты.",
    "INVALID_EMAIL": "Пожалуйста, укажите действующий адрес электронной почты.",
    "PLEASE_INPUT_MOBILE_PHONE": "Пожалуйста, введите свой номер телефона.",
    "INVALID_PHONE_NUMBER": "Укажите действительный номер телефона.",
    "PHONE_NUNBER_EXITS": "Номер телефона уже используется другим аккаунтом",
    "USERNAME_EXITS": "Это имя пользователя уже существует в нашей системе.",
    "ERROR_INPUT_VALID_USERNAME": "Имя пользователя может содержать только строчные буквы а также цифры и должно содержать от 3 до 20 символов.",
    "FIELD_IS_REQUIRED": "Это поле является обязательным.",
    "REFERRAL_CODE_INVALID": "Реферальный код недействителен.",
    "INVALID_USERNAME": "Укажите действительное имя пользователя.",
    "FIELD_IS_REQUIRED_BETWEEN_3_TO_20": "Имя пользователя должно содержать от 3 до 20 символов.",
    "PHONE_NUNBER_EXITS_IN_GROUP": ""
  },
  "errors": {
    "3034": "Этот номер существует в системе. Пожалуйста, введите другой номер.",
    "4001": "Этот номерной знак существует",
    "NETWORK_ERROR": "Ошибка: ошибка сети"
  },
  "general": {
    "CONTINUE": "Продолжить",
    "STEP_COMPLETE": "{0}/{1} Завершить",
    "STEP_HEADER": "Шаг {0}",
    "BACK": "Назад",
    "NEXT": "Вперед",
    "SUBMIT": "Отправить",
    "Clear_Selected_Items": "Очистить выбранные элементы",
    "no_results": "Нет результатов",
    "add_new_item": "Добавить новое"
  },
  "home": {
    "BECOME": "Станьте водителем %{name}.",
    "HEADING": "Введите основную информацию, чтобы начать работу",
    "APPLY_NOW": "Зарегистрироваться",
    "INDIVIDUAL_CAR_GOJO": "Транспорт/Такси - У меня личный автомобиль",
    "INDIVIDUAL_CAR": "Транспорт - У меня личный автомобиль",
    "COMPANY_CAR": "Транспорт - У меня служебный автомобиль",
    "DELIVERY_INDIVIDUAL_CAR": "Доставка - У меня личный автомобиль",
    "DELIVERY_COMPANY_CAR": "Доставка - У меня служебный автомобиль",
    "PLACEHOVER_SELECT_PRE_CITY": "Город",
    "PLACEHOVER_SELECT_REGISTRATION_TYPE": "Выберите тип водителя",
    "USERNAME": "Имя пользователя",
    "FIRST_NAME": "Имя",
    "LAST_NAME": "Фамилия",
    "EMAIL": "Эл. почта (необязательно)",
    "PHONE_NUMBER": "Номер мобильного телефона",
    "REFERRAL_CODE": "Реферальный код (необязательно)",
    "I_AGREE_TO": "Регистрируясь, я согласен с",
    "TERMS_OF_USE": "Условия обслуживания",
    "AND": "а также",
    "PRIVACY_POLICY": "Политика конфиденциальности",
    "I_AGREE_TO_AFTER": ".",
    "Company": "Supplier",
  },
  "finish": {
    "FINISH_1": "Благодарим вас за регистрацию в %{name}!",
    "FINISH_3": "Войдите в систему, чтобы начать работу!",
    "FINISH_USE_PASSWORD_1": "Я почти на месте!",
    "FINISH_USE_PASSWORD_2": "Чтобы активировать аккаунт и получать заказы, выполните следующие действия:",
    "FINISH_USE_PASSWORD_3": "1. Войдите в систему, используя номер телефона",
    "FINISH_USE_PASSWORD_4": "2. Пароль: <span style='font-weight: bold'>12345678</span>",
    "FINISH_USE_PASSWORD_5": "3. Заполните заявку",
    "OK": "Войти",
    "downloadApp": "Загрузите приложение <span style='color: %{color}' >%{name}</span> для водителей прямо сейчас и войдите в систему, указав свой номер телефона."
  },
  "datePlaceHolder": "MM/DD/YYY"
};