export const hi = {
  "name": "Hindi",
  "flagCode": "in",
  "locale": "hi-IN",
  "project": {
    "name": "चालक पंजीकरण"
  },
  "key": "hi",
  "validations": {
    "PLEASE_SELECT_CITY": "एक शहर चुनें।",
    "PLEASE_SELECT_SERVICE": "सेवा का चयन करें।",
    "PLEASE_SELECT_DRIVER_TYPE": "ड्राइवर प्रकार का चयन करें।",
    "PLEASE_INPUT_FIRSTNAME": "अपना पहला नाम दर्ज करें।",
    "PLEASE_INPUT_LASTNAME": "अपना अंतिम नाम दर्ज करें।",
    "PLEASE_INPUT_EMAIL": "अपना ईमेल दर्ज करें।",
    "INVALID_EMAIL": "कृपया एक मान्य ईमेल पता निर्दिष्ट करें।",
    "PLEASE_INPUT_MOBILE_PHONE": "एक मान्य फ़ोन नंबर दर्ज करें।",
    "INVALID_PHONE_NUMBER": "एक मान्य फ़ोन नंबर दर्ज करें।",
    "PHONE_NUNBER_EXITS": "फ़ोन नंबर पहले से ही किसी अन्य खाते के साथ प्रयोग में है।",
    "USERNAME_EXITS": "यह उपयोगकर्ता नाम हमारे सिस्टम पर पहले से मौजूद है।",
    "ERROR_INPUT_VALID_USERNAME": "उपयोगकर्ता नाम में केवल कुछ अक्षर और संख्याएँ होनी चाहिए और यह 3 से 20 वर्णों के बीच लंबा होना चाहिए",
    "FIELD_IS_REQUIRED": "यह फ़ील्ड आवश्यक है.",
    "REFERRAL_CODE_INVALID": "रेफरल कोड अमान्य है।",
    "INVALID_USERNAME": "एक मान्य उपयोगकर्ता नाम निर्दिष्ट करें।",
    "FIELD_IS_REQUIRED_BETWEEN_3_TO_20": "उपयोगकर्ता नाम 3 से 20 वर्णों के बीच लंबा होना चाहिए।",
    "PHONE_NUNBER_EXITS_IN_GROUP": ""
  },
  "errors": {
    "3034": "यह फोन सिस्टम में है। कृपया एक और दर्ज करें।",
    "4001": "लाइसेंस प्लेट निकलती है",
    "NETWORK_ERROR": "त्रुटि: नेटवर्क त्रुटि"
  },
  "general": {
    "CONTINUE": "जारी रखना",
    "STEP_COMPLETE": "{0}/{1} किया गया",
    "STEP_HEADER": "चरण {0}",
    "BACK": "फिर से",
    "NEXT": "अगला",
    "SUBMIT": "प्रस्तुत",
    "Clear_Selected_Items": "चयनित आइटम हटाएं",
    "no_results": "कोई परिणाम नहीं",
    "add_new_item": "नया जोड़ें"
  },
  "home": {
    "BECOME": "%{name} ड्राइवर बनें।",
    "HEADING": "आरंभ करने के लिए अपनी मूलभूत जानकारी दर्ज करें",
    "APPLY_NOW": "साइन अप करें",
    "INDIVIDUAL_CAR_GOJO": "परिवहन/टैक्सी - स्वयं का वाहन",
    "INDIVIDUAL_CAR": "परिवहन - मेरे स्वामित्व वाला वाहन",
    "COMPANY_CAR": "परिवहन - कंपनी वाहन",
    "DELIVERY_INDIVIDUAL_CAR": "डिलिवरी - मेरे स्वामित्व वाला वाहन",
    "DELIVERY_COMPANY_CAR": "डिलिवरी - कंपनी का वाहन",
    "PLACEHOVER_SELECT_PRE_CITY": "शहर",
    "PLACEHOVER_SELECT_REGISTRATION_TYPE": "ड्राइवर प्रकार का चयन करें",
    "USERNAME": "उपयोगकर्ता नाम",
    "FIRST_NAME": "संतोष",
    "LAST_NAME": "उपनाम",
    "EMAIL": "ईमेल (वैकल्पिक)",
    "PHONE_NUMBER": "मोबाइल नंबर",
    "REFERRAL_CODE": "रेफरल कोड (वैकल्पिक)",
    "I_AGREE_TO": "पंजीकरण करके, मैंने पढ़ लिया है और सहमत हूं",
    "TERMS_OF_USE": "सेवा की शर्तें",
    "AND": "और",
    "PRIVACY_POLICY": "गोपनीयता नीति",
    "I_AGREE_TO_AFTER": ".",
    "Company": "Supplier",
  },
  "finish": {
    "FINISH_1": "%{name} . के साथ पंजीकरण करने के लिए धन्यवाद",
    "FINISH_3": "आरंभ करने के लिए अभी लॉगिन करें!",
    "FINISH_USE_PASSWORD_1": "वहाँ लगभग!",
    "FINISH_USE_PASSWORD_2": "अपना खाता सक्रिय करने और नौकरी पाने के लिए इन चरणों का पालन करें:",
    "FINISH_USE_PASSWORD_3": "1. अपने पंजीकृत फोन नंबर के साथ लॉगिन करें",
    "FINISH_USE_PASSWORD_4": "2. पासवर्ड: <span style='font-weight: बोल्ड'>12345678</span>",
    "FINISH_USE_PASSWORD_5": "3. अपना आवेदन पूरा करें",
    "OK": "लॉग इन करने के लिए",
    "downloadApp": "अभी <span style='color: %{color}' >%{name}</span> ड्राइवर ऐप डाउनलोड करें और अपने रजिस्टर्ड फोन नंबर से लॉग इन करें।"
  },
  "datePlaceHolder": "MM/DD/YYYY"
};