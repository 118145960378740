import {
    LOADING_BAR_COUNTER_INCREASE,
    LOADING_BAR_COUNTER_DECREASE,
    LOADING_SPINER_SHOW,
    LOADING_SPINER_HIDE,
} from '../Actions/LoadingActions';

const initialState = {
    finished: 0,
    total: 0,
    isShowSpiner: false,
    isShowAddCardSpiner: false
}
export default function loadingBar(state = initialState, action = {}) {
    switch (action.type) {
        case LOADING_BAR_COUNTER_INCREASE: {
            const total = state.total + 1
            return { ...state, total: total }
        }
        case LOADING_BAR_COUNTER_DECREASE: {
            const finished = state.finished + 1
            if (finished != state.total) {
                return { ...state, finished: finished }
            } else {
                return {
                    ...state,
                    finished: 0,
                    total: 0,
                }
            }
        }
        case LOADING_SPINER_SHOW: {
            return { ...state, isShowSpiner: true }
        }
        case LOADING_SPINER_HIDE: {
            return { ...state, isShowSpiner: false }
        }
        default:
            return state;
    }
}