import { combineReducers } from 'redux';
import { i18nReducer } from 'react-redux-i18n';
import FleetInfoReducer from '../Reducer/FleetInfoReducer';
import LoadingReducer from "../Reducer/LoadingReducer";

const rootReducer = combineReducers({
    fleet: FleetInfoReducer,
    i18n: i18nReducer,
    loading: LoadingReducer,
})

export default rootReducer