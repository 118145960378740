export const ar = {
  "name": "Arabic",
  "flagCode": "sa",
  "locale": "ar-SA",
  "project": {
    "name": "تسجيل الكابتن"
  },
  "key": "ar",
  "validations": {
    "PLEASE_SELECT_CITY": "الرجاء تحديد المدينة.",
    "PLEASE_SELECT_SERVICE": "الرجاء تحديد الخدمة.",
    "PLEASE_SELECT_DRIVER_TYPE": "الرجاء تحديد نوع السائق.",
    "PLEASE_INPUT_FIRSTNAME": "يرجى ادخال الاسم الاول.",
    "PLEASE_INPUT_LASTNAME": "الرجاء إدخال اسمك الأخير.",
    "PLEASE_INPUT_EMAIL": "رجاءا أدخل بريدك الإلكتروني.",
    "INVALID_EMAIL": "الرجاء تحديد بريد إلكتروني صالح.",
    "PLEASE_INPUT_MOBILE_PHONE": "Please enter your phone number.",
    "INVALID_PHONE_NUMBER": "الرجاء تحديد رقم هاتف صالح.",
    "PHONE_NUNBER_EXITS": "رقم الهاتف مستخدم بالفعل مع حساب آخر.",
    "USERNAME_EXITS": "اسم المستخدم هذا موجود بالفعل على نظامنا.",
    "ERROR_INPUT_VALID_USERNAME": "لا يجوز أن يحتوي اسم المستخدم إلا على أحرف صغيره وأرقام ويجب أن يتراوح طوله بين 3 و 20 حرفًا",
    "FIELD_IS_REQUIRED": "هذه الخانة مطلوبه.",
    "REFERRAL_CODE_INVALID": "رمز الإحالة غير صالح.",
    "INVALID_USERNAME": "الرجاء تحديد اسم مستخدم صالح.",
    "FIELD_IS_REQUIRED_BETWEEN_3_TO_20": "يجب أن يكون اسم المستخدم بين 3 و 20 حرفًا.",
    "PHONE_NUNBER_EXITS_IN_GROUP": ""
  },
  "errors": {
    "3034": "This phone has been existing on the system. Please input another one.",
    "4001": "رقم لوحة مسجلة مسبقا لدينا.",
    "NETWORK_ERROR": "Error: Network Error"
  },
  "general": {
    "CONTINUE": "Continue",
    "STEP_COMPLETE": "{0} / {1} مكتمل",
    "STEP_HEADER": "الخطوة {0}",
    "BACK": "Back",
    "NEXT": "Next",
    "SUBMIT": "Submit",
    "Clear_Selected_Items": "Clear selected items",
    "no_results": "No results",
    "add_new_item": "اضف جديد"
  },
  "home": {
    "BECOME": "كن سائق ٪{name}.",
    "HEADING": "أدخل معلوماتك الأساسية للبدء",
    "APPLY_NOW": "سجل",
    "INDIVIDUAL_CAR_GOJO": "النقل / التاكسي - أمتلك سيارة",
    "INDIVIDUAL_CAR": "النقل - أمتلك مركبة",
    "COMPANY_CAR": "النقل - سيارة الشركة",
    "DELIVERY_INDIVIDUAL_CAR": "التوصيل - أنا أملك مركبة",
    "DELIVERY_COMPANY_CAR": "التسليم - سيارة الشركة",
    "PLACEHOVER_SELECT_PRE_CITY": "المدينة",
    "PLACEHOVER_SELECT_REGISTRATION_TYPE": "حدد نوع السائق",
    "USERNAME": "صارف نام",
    "FIRST_NAME": "الاسم الأول",
    "LAST_NAME": "اسم العائلة",
    "EMAIL": "عنوان البريد الإلكتروني (اختياري)",
    "PHONE_NUMBER": "رقم هاتف",
    "REFERRAL_CODE": "رمز الدعوة (اختياري)",
    "I_AGREE_TO": "بالتسجيل ، قرأت ووافقت على",
    "TERMS_OF_USE": "شروط الخدمة",
    "AND": "و",
    "PRIVACY_POLICY": "سياسة الخصوصية ",
    "I_AGREE_TO_AFTER": ".",
    "Company": "Supplier",
  },
  "finish": {
    "FINISH_1": "شكرًا لك على الاشتراك مع %{name}!",
    "FINISH_3": "   سجل الدخول الآن لتبدأ!",
    "FINISH_USE_PASSWORD_1": "اوشكت على الانتهاء!",
    "FINISH_USE_PASSWORD_2": "لتفعيل حسابك واستلام الطلبات، يرجى اتباع الخطوات التالية:",
    "FINISH_USE_PASSWORD_3": "1. تسجيل الدخول باستخدام رقم هاتفك المسجل",
    "FINISH_USE_PASSWORD_4": "2. كلمة المرور: <span style='font-weight: bold'>12345678</span>",
    "FINISH_USE_PASSWORD_5": "3. أكمل طلبك",
    "OK": "تسجيل الدخول",
    "downloadApp": "قم بتنزيل  <span style='color: %{color}'>%{name}</span> الخاص بالسائق الآن و سجل الدخول باستخدام رقم هاتفك المسجل"
  },
  "datePlaceHolder": "MM/DD/YYYY"
};