import { FETCH_FLEET_INFO_REQUEST, FETCH_FLEET_INFO_SUCCESS, FETCH_FLEET_INFO_FAILURE } from '../Actions/FleetInfoActions';

const initialState = {
    loading: false,
    fleetInfo: {},
    error: ''
}

const fleetInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_FLEET_INFO_REQUEST:
            return {
                ...state,
                loading: true
            }

        case FETCH_FLEET_INFO_SUCCESS:
            return {
                loading: false,
                fleetInfo: action.payload,
                error: ''
            }

        case FETCH_FLEET_INFO_FAILURE:
            return {
                loading: false,
                fleetInfo: {},
                error: action.payload
            }

        default:
            return state
    }
}

export default fleetInfoReducer