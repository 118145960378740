import React from 'react';
import { Field } from 'formik';
import { Form } from 'react-bootstrap';
import { I18n } from "react-redux-i18n";

function TermForm(props) {
    const { label, name, options, linkTerm, linkPrivacy, touched, errors, fleetName, ...rest } = props;

    return (
        <Form.Group>
            <Field name={name} {...rest}>
                {
                    ({ field }) => {
                        return options.map(option => {
                            return (
                                <React.Fragment key={option.key} >
                                    <label className={name}>
                                        {I18n.t("home.I_AGREE_TO", { name: fleetName })}{' '}
                                        <a
                                            href={linkTerm}
                                            target="_blank"
                                            className="term-of-service"
                                            style={{ display: "inline-block" }}
                                        >
                                            {I18n.t("home.TERMS_OF_USE")}
                                        </a>
                                        {' '}{I18n.t("home.AND")}{' '}
                                        <a
                                            href={linkPrivacy}
                                            target="_blank"
                                            className="term-of-service"
                                            style={{ display: "inline-block" }}
                                        >
                                            {I18n.t("home.PRIVACY_POLICY")}
                                        </a>
                                        {' '}{I18n.t("home.I_AGREE_TO_AFTER")}{' '}
                                        <input
                                            type="checkbox"
                                            id={name}
                                            {...field}
                                            value={option.value}
                                            checked={field.value === option.value}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </React.Fragment>
                            )
                        })
                    }
                }
            </Field>
        </Form.Group>
    )
}

export default TermForm