export const th = {
  "name": "Thai",
  "flagCode": "th",
  "locale": "th-TH",
  "project": {
    "name": "สมัครคนขับ"
  },
  "key": "th",
  "validations": {
    "PLEASE_SELECT_CITY": "กรุณาเลือกเมือง",
    "PLEASE_SELECT_SERVICE": "กรุณาเลือกบริการ",
    "PLEASE_SELECT_DRIVER_TYPE": "กรุณาเลือกประเภทคนขับ",
    "PLEASE_INPUT_FIRSTNAME": "กรุณาใส่ชื่อ",
    "PLEASE_INPUT_LASTNAME": "กรุณาใส่นามสกุล",
    "PLEASE_INPUT_EMAIL": "กรุณาใส่อีเมล์",
    "INVALID_EMAIL": "โปรดระบุอีเมล์ที่ถูกต้อง",
    "PLEASE_INPUT_MOBILE_PHONE": "กรุณาใส่เบอร์โทรศัพท์",
    "INVALID_PHONE_NUMBER": "โปรดระบุหมายเลขโทรศัพท์ที่ถูกต้อง",
    "PHONE_NUNBER_EXITS": "เบอร์โทรศัพท์นี้ได้ลงทะเบียนแล้ว",
    "USERNAME_EXITS": "ชื่อผู้ใช้งานนี้มีอยู่ในระบบเเล้ว",
    "ERROR_INPUT_VALID_USERNAME": "ชื่อผู้ใช้งานจะต้องประกอบด้วยตัวอักษร&ตัวเลข ระหว่าง 3 ถึง 20 ตัวอักษร",
    "FIELD_IS_REQUIRED": "ต้องระบุ",
    "REFERRAL_CODE_INVALID": "โค๊ดอ้างอิงไม่ถูกต้อง",
    "INVALID_USERNAME": "โปรดระบุชื่อผู้ใช้งานที่ถูกต้อง",
    "FIELD_IS_REQUIRED_BETWEEN_3_TO_20": "ชื่อผู้ใช้งานต้องมี 3 ถึง 20 ตัวอักษร",
    "PHONE_NUNBER_EXITS_IN_GROUP": "เบอร์โทรศัพท์นี้ได้ลงทะเบียนแล้ว"
  },
  "errors": {
    "3034": "หมายเลขโทรศัพท์นี้ได้ถูกลงทะเบียนเเล้ว กรุณาใส่หมายเลขใหม่",
    "4001": "หมายเลขทะเบียนรถยนต์นี้ได้ลงทะเบียนเเล้ว",
    "NETWORK_ERROR": "ระบบขัดข้อง"
  },
  "general": {
    "CONTINUE": "ต่อเนื่อง",
    "STEP_COMPLETE": "{0}/{1} สมบูรณ์",
    "STEP_HEADER": "ขั้นตอนที่",
    "BACK": "กลับ",
    "NEXT": "ต่อไป",
    "SUBMIT": "ส่ง",
    "Clear_Selected_Items": "ลบที่เลือกไว้",
    "no_results": "ไม่มีผลการค้นหา",
    "add_new_item": "เพิ่มใหม่"
  },
  "home": {
    "BECOME": "ต้องการเป็น %{name} คนขับ",
    "HEADING": "กรุณาใส่ข้อมูลเพื่อเริ่มต้น",
    "APPLY_NOW": "สมัคร",
    "INDIVIDUAL_CAR_GOJO": "รถขนส่ง/รถเเท็กซี่ - เป็นเจ้าของรถ",
    "INDIVIDUAL_CAR": "รถรับจ้าง-เป็นเจ้าของรถ",
    "COMPANY_CAR": "รถรับจ้าง-เป็นรถบริษัท",
    "DELIVERY_INDIVIDUAL_CAR": "รับส่งสินค้า-เป็นเจ้าของรถ",
    "DELIVERY_COMPANY_CAR": "รับส่งสินค้า-เป็นรถบริษัท",
    "PLACEHOVER_SELECT_PRE_CITY": "เมือง",
    "PLACEHOVER_SELECT_REGISTRATION_TYPE": "เลือกชนิดรถ",
    "USERNAME": "ชื่อบัญชีล๊อกอิน",
    "FIRST_NAME": "ชื่อ",
    "LAST_NAME": "นามสกุล",
    "EMAIL": "อีเมล์",
    "PHONE_NUMBER": "เบอร์โทรศัพท์มือถือ",
    "REFERRAL_CODE": "รหัสอ้างอิง",
    "I_AGREE_TO": "การลงทะเบียนนี้ ข้าพเจ้าได้อ่านเเละเห็นด้วยกับ",
    "TERMS_OF_USE": "เงื่อนไขการให้บริการ",
    "AND": "เเละ",
    "PRIVACY_POLICY": "นโยบายความเป็นส่วนตัว",
    "I_AGREE_TO_AFTER": ".",
    "Company": "Supplier",
  },
  "finish": {
    "FINISH_1": "ขอบคุณสำหรับการสมัครกับ %{name}!",
    "FINISH_3": "เข้าระบบเเละเริ่มใช้งานเดี๋ยวนี้",
    "FINISH_USE_PASSWORD_1": "ใกล้สำเร็จ",
    "FINISH_USE_PASSWORD_2": "เพื่อเปิดการใช้งานบัญชีของท่านเเละรับงาน กรุณาทำตามขั้นตอนนี้",
    "FINISH_USE_PASSWORD_3": "1. เข้าระบบด้วยเบอร์โทรศัพท์ที่ท่านลงทะเบียนไว้",
    "FINISH_USE_PASSWORD_4": "2. รหัสผ่าน: <span style='font-weight: bold'>12345678</span>",
    "FINISH_USE_PASSWORD_5": "3. จัดการใบสมัครให้สมบูรณ์",
    "OK": "เข้าระบบ",
    "downloadApp": "ดาวน์โหลด <span style='color: %{color}' >%{name}</span> เเอปสำหรับคนขับเเละเข้าระบบด้วยหมายเลขโทรศัพท์ที่ลงทะเบียน"
  },
  "datePlaceHolder": "เดือน/วัน/ปี"
};