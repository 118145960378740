import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { I18n, setLocale } from "react-redux-i18n";
import _ from "lodash";
import { decode } from 'js-base64';

import { fetchFleetInfo } from "../Actions/FleetInfoActions";
import LoadingSpiner from "../Constants/LoadingSpiner/LoadingSpiner";
import Header from "../Components/Header/Header";
import SignUpForm from "../Components/SignUpForm/SignUpForm";

import { translations } from "../Constants/language/languages";

function HomePage(props) {
  // http://localhost:3000/?fleetId=hoanglocal&desktop=true
  // http://localhost:3001/?fleetToken=fdt42726
  // http://localhost:3001/?fleetId=hoanglocal&referralID=TUONGVIQ
  // http://localhost:3001/?fleetId=hoanglocal&referralCode=TUONGVIQ
  const parsedQuery = queryString.parse(props.location.search);
  const [fleetId, setFleetId] = useState(parsedQuery.fleetId);
  const appName = parsedQuery.appName;
  const fleetToken = parsedQuery.fleetToken;
  const f = parsedQuery.f;
  const fleetIdQuery = parsedQuery.fleetId;
  const isIframe = parsedQuery.isIframe;
  const fpc = parsedQuery.fpc;
  const desktop = Boolean(parsedQuery.desktop);
  const referralID = parsedQuery.referralID
    ? parsedQuery.referralID
    : parsedQuery.referralCode
    ? parsedQuery.referralCode
    : "";
  const [background, setBackground] = useState("transform");
  const [mainColor, setMainColor] = useState("transform");
  const [dataLoading, setDataLoading] = useState(true);
  const [drvSignUpLanguages, setDrvSignUpLanguages] = useState([]);
  const [langFleet, setLangFleet] = useState("");
  const [locale, setLocale] = useState("");
  const [countryFleet, setCountryFleet] = useState("");
  const [companies, setCompanies] = useState([]);
  const [isSubDomain, setIsSubDomain] = useState({ enable: false });
  const [company, setCompany] = useState({});
  const [showSupplierSelection, setShowSupplierSelection] = useState(false);

  useEffect(() => {
    if (fleetToken || f) {
      const params = { fleetToken: fleetToken || f };
      props.fetchFleetInfo(params);
    }
    if (fleetIdQuery) {
      const params = { fleetId: fleetIdQuery };
      props.fetchFleetInfo(params);
    }

    const parsedData = window.location.host.split(".");
    console.log("URL: ", parsedData);
    if (parsedData.length >= 3) {
      const subDomain = parsedData[0];
      console.log("Sub-Domain: ", subDomain);
      setIsSubDomain({ subDomain, enable: true });
      if (!fleetIdQuery && parsedData[1] === "signup") {
        setFleetId("gojosg");
        const params = { fleetId: "gojosg" };
        props.fetchFleetInfo(params);
      }
    } else {
      setIsSubDomain({ enable: false });
    }
  }, [fleetToken, f, fleetIdQuery]);

  useEffect(() => {
    if (props.fleet.fleetInfo.fleet) {
      const { providerSetting, countryCode } = props.fleet.fleetInfo.fleet;
      let { language } = props.fleet.fleetInfo.fleet;
      let backgroundColor = "#F7F9FB";
      let mainColor = "#01A4CE";
      if (providerSetting) {
        backgroundColor = providerSetting.otherSetting.backgroundColor
          ? providerSetting.otherSetting.backgroundColor
          : "#F7F9FB";
        mainColor = providerSetting.otherSetting.mainColor
          ? providerSetting.otherSetting.mainColor
          : "#01A4CE";
        setBackground(backgroundColor);
        setMainColor(mainColor);

        if (providerSetting?.otherSetting?.showSupplierSelection) {
          setShowSupplierSelection(providerSetting?.otherSetting?.showSupplierSelection)
        }
      }
      if (language) {
        let newlanguage = language;
        if (countryCode === "GB" && language === "en") {
          newlanguage = newlanguage + countryCode;
        } else {
          newlanguage = language.replace("_", "").replace("-", "");
        }
        if (translations[newlanguage]) {
          localStorage.setItem("driver-signup-language", newlanguage);
          localStorage.setItem("locale", translations[newlanguage].locale);
          props.setLocale(newlanguage);
          setLangFleet(newlanguage);
        } else {
          localStorage.setItem("driver-signup-language", "en");
          localStorage.setItem("locale", "en-US");
          setLocale("en-US");
        }
      }
      if (!fleetId) {
        setFleetId(props.fleet.fleetInfo.fleet.fleetId);
      }
      if (countryFleet === "") {
        setCountryFleet(countryCode);
      }
    }

    if (props.fleet.fleetInfo.drvSignUpLanguages) {
      setDrvSignUpLanguages(props.fleet.fleetInfo.drvSignUpLanguages);
    }

    if (
      props.fleet.fleetInfo.company &&
      props.fleet.fleetInfo.company.list.length > 0
    ) {
      setCompanies(props.fleet.fleetInfo.company.list);
    }

    // URL create from setting company on NewCC
  }, [props.fleet.fleetInfo]);

  useEffect(() => {
    document.body.style = `background: ${background};`;
  }, [background]);

  useEffect(() => {
    if (langFleet) {
      let fleetLang;
      let languages = [];
      _.forEach(drvSignUpLanguages, function (lang, i) {
        fleetLang = lang.substring(0, 2);
        if (translations.hasOwnProperty(fleetLang)) {
          languages.push(translations[fleetLang]);
        }
      });
      switchLanguage(langFleet, languages);
    }
  }, [langFleet]);

  const switchLanguage = (lang, languages) => {
    _.forEach(languages, function (language, i) {
      if (lang === language.key) {
        // if (!localStorage.getItem("locale")) {
        //     localStorage.setItem("locale", language.locale);
        // }
        localStorage.setItem("locale", language.locale);
        setLocale(language.locale);
      }
    });
  };

  useEffect(() => {
    // get company info from URL
    if(parsedQuery.c && showSupplierSelection) {
      const findCompany = companies.find(
        (e) => e._id === parsedQuery.c
      );
      if (findCompany) {
        setCompany(findCompany);
      }
      return 
    }

    if (isSubDomain.enable && isSubDomain.subDomain) {
      const findCompany = companies.find(
        (e) => e.name.toLowerCase() === isSubDomain.subDomain
      );
      if (findCompany) {
        setCompany(findCompany);
      }
    }

  }, [companies]);

  useEffect(() => {
    if (dataLoading !== props.fleet.loading) {
      setDataLoading(props.fleet.loading);
    }
  }, [props.fleet.loading]);

  const getOSinfor = () => {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let OSName = "Unknown OS";
    if (userAgent.indexOf("Win") !== -1) OSName = "Driver signup (Web)";
    if (userAgent.indexOf("Mac") !== -1) OSName = "Driver signup (Web)";
    if (userAgent.indexOf("Linux") !== -1) OSName = "Driver signup (Web)";
    if (/android/i.test(userAgent)) OSName = "Driver signup (Android)";
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
      OSName = "Driver signup (iOS)";
    return OSName;
  };

  const switchFleet = (fleet) => {
    setFleetId(fleet.fleetId);
    setCountryFleet(fleet.countryCode);
    const params = { fleetId: fleet.fleetId };
    props.fetchFleetInfo(params);
  };

  return (
    <div className="form-signup">
      {desktop ? null : dataLoading ? null : (
        <Header
          fleetInfo={props.fleet.fleetInfo}
          countryFleet={countryFleet}
          switchFleet={switchFleet}
        />
      )}
      {dataLoading ? null : (
        <SignUpForm
          isIframe={isIframe}
          fpc={fpc}
          fleetInfo={props.fleet.fleetInfo}
          fleetId={fleetId}
          OSInfo={getOSinfor()}
          mainColor={mainColor}
          desktop={desktop}
          appName={appName || ""}
          localeFleet={locale}
          isSubDomain={isSubDomain}
          company={company}
          companies={companies}
          showSupplierSelection={showSupplierSelection}
          referralID={referralID}
          urlQueryHasCompany={parsedQuery.c}
        />
      )}

      <LoadingSpiner show={props.loading.isShowSpiner} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    fleet: state.fleet,
    loading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchFleetInfo: (params) => dispatch(fetchFleetInfo(params)),
    setLocale: (data) => dispatch(setLocale(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
