export const LOADING_BAR_COUNTER_INCREASE = 'LOADING_BAR_COUNTER_INCREASE';
export const LOADING_BAR_COUNTER_DECREASE = 'LOADING_BAR_COUNTER_DECREASE';
export const LOADING_SPINER_SHOW = 'LOADING_SPINER_SHOW';
export const LOADING_SPINER_HIDE = 'LOADING_SPINER_HIDE';

export function loadingIncrease(){
    return{
        type:LOADING_BAR_COUNTER_INCREASE
    }
}
export function loadingDecrease(){
    return{
        type:LOADING_BAR_COUNTER_DECREASE
    }
}
export function showLoadingSpiner(){
    return{
        type:LOADING_SPINER_SHOW
    }
}
export function hideLoadingSpiner(){
    return{
        type:LOADING_SPINER_HIDE
    }
}