export const am = {
  "name": "Amharic",
  "flagCode": "et",
  "locale": "am-ET",
  "project": {
    "name": "Driver Signup"
  },
  "key": "am",
  "validations": {
    "PLEASE_SELECT_CITY": "እባክዎን መንዳት የሚፈልጉትን ከተማ ይምረጡ።",
    "PLEASE_SELECT_SERVICE": "እባክዎን አገልግሎት ይምረጡ።",
    "PLEASE_SELECT_DRIVER_TYPE": "እባክዎን የሾፌር ዓይነት ይምረጡ።",
    "PLEASE_INPUT_FIRSTNAME": "እባክዎን የመጀመሪያ ስምዎን ይግለጹ።",
    "PLEASE_INPUT_LASTNAME": "እባክዎን የአባትዎን ስም ይግለጹ።",
    "PLEASE_INPUT_EMAIL": "እባክዎን ኢሜልዎን ይግለጹ።",
    "INVALID_EMAIL": "እባክዎ የሚሰራ ኢሜል ያስገቡ።",
    "PLEASE_INPUT_MOBILE_PHONE": "እባክዎ ስልክ ቁጥርዎን ያስገቡ።",
    "INVALID_PHONE_NUMBER": "እባክዎን ትክክለኛውን የስልክ ቁጥር ይግለጹ።",
    "PHONE_NUNBER_EXITS": "ስልክ ከሌላ መለያ ጋር አስቀድሞ ጥቅም ላይ ውሏል።",
    "USERNAME_EXITS": "የተጠቃሚ ስም ቀድሞውኑ በስርዓቱ ውስጥ አለ። እባክዎን ሌላ ያስገቡ።",
    "ERROR_INPUT_VALID_USERNAME": "የተጠቃሚ ስም ዝቅተኛ ፊደሎችን እና ቁጥሮችን ብቻ ሊይዝ ይችላል እና ከ 3 እስከ 20 ፊደሎች ርዝመት ሊኖረው ይገባል፡፡",
    "FIELD_IS_REQUIRED": "ይሄ ቦታ ይፈለጋል.",
    "REFERRAL_CODE_INVALID": "የማጣቀሻ ኮድ ልክ ያልሆነ ነው።",
    "INVALID_USERNAME": "እባክዎ የሚሰራ የተጠቃሚ ስም ይግለጹ።",
    "FIELD_IS_REQUIRED_BETWEEN_3_TO_20": "የተጠቃሚ ስም ከ 3 እስከ 20 ቁምፊዎች መሆን አለበት።",
    "PHONE_NUNBER_EXITS_IN_GROUP": ""
  },
  "errors": {
    "3034": "ይህ ስልክ በስርዓቱ ላይ ቆይቷል። እባክዎን ሌላ ያስገቡ።",
    "4001": "የሰሌዳው ቁጥሩ በሲስተም ላይ ይገኛል",
    "NETWORK_ERROR": "ስህተት የአውታረ መረብ ስህተት"
  },
  "general": {
    "CONTINUE": "ቀጥል",
    "STEP_COMPLETE": "{0}/{1} ተጠናቀቀ",
    "STEP_HEADER": "ደረጃ {0}",
    "BACK": "ተመለስ",
    "NEXT": "ቀጥሎ",
    "SUBMIT": "አስረክብ",
    "Clear_Selected_Items": "የተመረጡ ንጥሎችን ያፅዱ",
    "no_results": "ምንም ውጤቶች የሉም",
    "add_new_item": "አዲስ አስገባ"
  },
  "home": {
    "BECOME": "%{Name} ሾፌር ይሁኑ።",
    "HEADING": "ለመጀመር መረጃዎን ያስገቡ።",
    "APPLY_NOW": "ተመዝገብ",
    "INDIVIDUAL_CAR_GOJO": "መጓጓዣ/ታክሲ - እኔ ባለቤት ነኝ",
    "INDIVIDUAL_CAR": "መጓጓዣ - እኔ ባለቤት ነኝ",
    "COMPANY_CAR": "መጓጓዣ - የኩባንያ ተሽከርካሪ",
    "DELIVERY_INDIVIDUAL_CAR": "ደሊቨሪ - እኔ ባለቤት ነኝ",
    "DELIVERY_COMPANY_CAR": "ደሊቨሪ - የኩባንያ ተሽከርካሪ",
    "PLACEHOVER_SELECT_PRE_CITY": "ከተማ",
    "PLACEHOVER_SELECT_REGISTRATION_TYPE": "የአሽከርካሪ ዓይነት ይምረጡ",
    "USERNAME": "የተጠቃሚ ስም",
    "FIRST_NAME": "የመጀመሪያ ስም",
    "LAST_NAME": "የአባት ሥም",
    "EMAIL": "ኢሜል (አማራጭ)",
    "PHONE_NUMBER": "የሞባይል ስልክ ቁጥር",
    "REFERRAL_CODE": "የጥቆማ ኮድ (አማራጭ)",
    "I_AGREE_TO": "በመመዝገብ ፣ አንብቤ ተስማምቻለሁ ለ",
    "TERMS_OF_USE": "የአገልግሎት ውሎች",
    "AND": "እና",
    "PRIVACY_POLICY": "የግለኝነት ፖሊሲ",
    "I_AGREE_TO_AFTER": ".",
    "Company": "Supplier",
  },
  "finish": {
    "FINISH_1": "በ %{name} ስለተመዘገቡ እናመሰግናለን",
    "FINISH_3": "ለመጀመር አሁን ይግቡ!",
    "FINISH_USE_PASSWORD_1": "ሊደርስ ነው!",
    "FINISH_USE_PASSWORD_2": "መለያዎን ለማግበር እና ሥራዎችን ለመቀበል እባክዎ እነዚህን ደረጃዎች ይከተሉ",
    "FINISH_USE_PASSWORD_3": "1. በተመዘገበ ስልክ ቁጥርዎ ይግቡ",
    "FINISH_USE_PASSWORD_4": "2. የይለፍ ቃል <span style = 'font-weight: bold'> 12345678 </span>",
    "FINISH_USE_PASSWORD_5": "3. ማመልከቻዎን ያጠናቅቁ",
    "OK": "ግባ",
    "downloadApp": "<Span style = 'color: %{color}'> %{name} </span> የአሽከርካሪ መተግበሪያን አሁን ያውርዱ እና በተመዘገበ ስልክ ቁጥርዎ ይግቡ።"
  },
  "datePlaceHolder": "ወር/ቀን/አም"
};