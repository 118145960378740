export const sv = {
    "name": "Swedish",
    "flagCode": "se",
    "locale": "sv",
    "project": {
      "name": "Driver Signup"
    },
    "key": "sv",
    "validations": {
      "PLEASE_SELECT_CITY": "Vänligen välj stad.",
      "PLEASE_SELECT_SERVICE": "Vänligen välj tjänst.",
      "PLEASE_SELECT_DRIVER_TYPE": "Vänligen välj förartyp.",
      "PLEASE_INPUT_FIRSTNAME": "Vänligen ange ditt förnamn.",
      "PLEASE_INPUT_LASTNAME": "Vänligen ange ditt efternamn.",
      "PLEASE_INPUT_EMAIL": "Vänligen ange din e-postadress.",
      "INVALID_EMAIL": "Vänligen ange en giltig e-postadress.",
      "PLEASE_INPUT_MOBILE_PHONE": "Vänligen ange ditt telefonnummer.",
      "INVALID_PHONE_NUMBER": "Vänligen ange ett giltigt telefonnummer.",
      "PHONE_NUNBER_EXITS": "Telefonnumret används redan med ett annat konto.",
      "USERNAME_EXITS": "Detta användarnamn finns redan i vårt system.",
      "ERROR_INPUT_VALID_USERNAME": "Användarnamn får endast innehålla små bokstäver och siffror och måste vara mellan 3 och 20 tecken långt",
      "FIELD_IS_REQUIRED": "Detta fält är obligatoriskt.",
      "REFERRAL_CODE_INVALID": "Hänvisningskoden är ogiltig.",
      "INVALID_USERNAME": "Vänligen ange ett giltigt användarnamn.",
      "FIELD_IS_REQUIRED_BETWEEN_3_TO_20": "Användarnamnet måste vara mellan 3 och 20 tecken.",
      "PHONE_NUNBER_EXITS_IN_GROUP": ""
    },
    "errors": {
      "3034": "Denna telefon finns redan i systemet. Vänligen ange en annan.",
      "4001": "Registreringsnumret finns redan.",
      "NETWORK_ERROR": "Fel: Nätverksfel"
    },
    "general": {
      "CONTINUE": "Fortsätt",
      "STEP_COMPLETE": "{0}/{1} Slutförd",
      "STEP_HEADER": "Steg {0}",
      "BACK": "Tillbaka",
      "NEXT": "Nästa",
      "SUBMIT": "Skicka",
      "Clear_Selected_Items": "Rensa valda objekt",
      "no_results": "Inga resultat",
      "add_new_item": "Lägg till ny"
    },
    "home": {
      "BECOME": "Bli en %{name} förare.",
      "HEADING": "Ange din grundläggande information för att komma igång",
      "APPLY_NOW": "Registrera dig",
      "INDIVIDUAL_CAR_GOJO": "Transport/Taxi - Jag äger fordon",
      "INDIVIDUAL_CAR": "Transport - Jag äger fordon",
      "COMPANY_CAR": "Transport - Företagsfordon",
      "DELIVERY_INDIVIDUAL_CAR": "Leverans - Jag äger fordon",
      "DELIVERY_COMPANY_CAR": "Leverans - Företagsfordon",
      "PLACEHOVER_SELECT_PRE_CITY": "Stad",
      "PLACEHOVER_SELECT_REGISTRATION_TYPE": "Välj förartyp",
      "USERNAME": "Användarnamn",
      "FIRST_NAME": "Förnamn",
      "LAST_NAME": "Efternamn",
      "EMAIL": "E-post (valfritt)",
      "PHONE_NUMBER": "Mobilnummer",
      "REFERRAL_CODE": "Hänvisningskod (valfritt)",
      "I_AGREE_TO": "Genom att registrera mig har jag läst och godkänner",
      "TERMS_OF_USE": "Användarvillkor",
      "AND": "och",
      "PRIVACY_POLICY": "Integritetspolicy",
      "I_AGREE_TO_AFTER": "."
    },
    "finish": {
      "FINISH_1": "Tack för att du registrerade dig hos %{name}!",
      "FINISH_3": "Logga in nu för att komma igång!",
      "FINISH_USE_PASSWORD_1": "Nästan där!",
      "FINISH_USE_PASSWORD_2": "För att aktivera ditt konto och få jobb, vänligen följ dessa steg:",
      "FINISH_USE_PASSWORD_3": "Logga in med ditt registrerade telefonnummer",
      "FINISH_USE_PASSWORD_4": "Logga in med ditt registrerade telefonnummer",
      "FINISH_USE_PASSWORD_5": "Slutför din ansökan",
      "OK": "Login",
      "downloadApp": "Ladda ner <span style='color: %{color}' >%{name}</span> förare-app nu och logga in med ditt registrerade telefonnummer."
    },
    "datePlaceHolder": "MM/DD/YYYY"
  };