export const es = {
  "name": "Spanish",
  "flagCode": "es",
  "locale": "es-ES",
  "project": {
    "name": "Registro de conductor"
  },
  "key": "es",
  "validations": {
    "PLEASE_SELECT_CITY": "Seleccione la ciudad.",
    "PLEASE_SELECT_SERVICE": "Seleccione el servicio.",
    "PLEASE_SELECT_DRIVER_TYPE": "Seleccione el tipo de controlador.",
    "PLEASE_INPUT_FIRSTNAME": "Por favor, introduzca su nombre de pila.",
    "PLEASE_INPUT_LASTNAME": "Por favor ingrese su apellido.",
    "PLEASE_INPUT_EMAIL": "Por favor introduzca su correo electrónico.",
    "INVALID_EMAIL": "Por favor, especifique un correo electrónico válido.",
    "PLEASE_INPUT_MOBILE_PHONE": "Por favor, introduzca su número de teléfono.",
    "INVALID_PHONE_NUMBER": "Especifique un número de teléfono válido.",
    "PHONE_NUNBER_EXITS": "Número de teléfono ya en uso con otra cuenta.",
    "USERNAME_EXITS": "Este nombre de usuario ya existe en nuestro sistema.",
    "ERROR_INPUT_VALID_USERNAME": "El nombre de usuario solo puede contener letras minúsculas y números y debe tener entre 3 y 20 caracteres",
    "FIELD_IS_REQUIRED": "Este campo es requerido.",
    "REFERRAL_CODE_INVALID": "El código de referencia no es válido.",
    "INVALID_USERNAME": "Por favor, especifique un nombre de usuario válido.",
    "FIELD_IS_REQUIRED_BETWEEN_3_TO_20": "El nombre de usuario debe tener entre 3 y 20 caracteres.",
    "PHONE_NUNBER_EXITS_IN_GROUP": "Número de teléfono ya en uso con otra cuenta."
  },
  "errors": {
    "3034": "Este teléfono ha existido en el sistema.  Introduzca otro.",
    "4001": "La matrícula es salidas",
    "NETWORK_ERROR": "Error: error de red"
  },
  "general": {
    "CONTINUE": "Continuar",
    "STEP_COMPLETE": "{0} / {1} Completo",
    "STEP_HEADER": "Paso {0}",
    "BACK": "atrás",
    "NEXT": "próximo",
    "SUBMIT": "Entregar",
    "Clear_Selected_Items": "Borrar elementos seleccionados",
    "no_results": "No hay resultados",
    "add_new_item": "Agregar nuevo"
  },
  "home": {
    "BECOME": "Conviértete en un controlador de %{name}.",
    "HEADING": "Ingrese su información básica para comenzar",
    "APPLY_NOW": "Inscribirse",
    "INDIVIDUAL_CAR_GOJO": "Transporte / Taxi - Tengo vehículo propio",
    "INDIVIDUAL_CAR": "Transporte - Tengo vehículo propio",
    "COMPANY_CAR": "Transporte - Vehículo de empresa",
    "DELIVERY_INDIVIDUAL_CAR": "Entrega - Tengo vehículo propio",
    "DELIVERY_COMPANY_CAR": "Entrega - Vehículo de empresa",
    "PLACEHOVER_SELECT_PRE_CITY": "Ciudad",
    "PLACEHOVER_SELECT_REGISTRATION_TYPE": "Seleccione el tipo de controlador",
    "USERNAME": "Nombre de usuario",
    "FIRST_NAME": "Nombre de pila",
    "LAST_NAME": "Apellido",
    "EMAIL": "Correo electrónico (Opcional)",
    "PHONE_NUMBER": "Número de teléfono móvil",
    "REFERRAL_CODE": "Código de Referencia (Opcional)",
    "I_AGREE_TO": "Al registrarme, he leído y acepto las",
    "TERMS_OF_USE": "Términos de servicio",
    "AND": "y",
    "PRIVACY_POLICY": "Política de privacidad",
    "I_AGREE_TO_AFTER": ".",
    "Company": "Supplier",
  },
  "finish": {
    "FINISH_1": "¡Gracias por registrarte con %{name}!",
    "FINISH_3": "¡Inicie sesión ahora para comenzar!",
    "FINISH_USE_PASSWORD_1": "¡Casi llegamos!",
    "FINISH_USE_PASSWORD_2": "Para activar su cuenta y recibir trabajos, siga estos pasos:",
    "FINISH_USE_PASSWORD_3": "1. Inicie sesión con su número de teléfono registrado",
    "FINISH_USE_PASSWORD_4": "2. Contraseña: <span style = 'font-weight: bold'> 12345678 </span>",
    "FINISH_USE_PASSWORD_5": "3. Complete su solicitud",
    "OK": "Acceso",
    "downloadApp": "Descargue la aplicación del controlador <span style='color: %{color}' >%{name}</span> ahora e inicie sesión con su número de teléfono registrado."
  },
  "datePlaceHolder": "DD/MM/AAAA"
};