export const zhCN = {
  "name": "Simplified Chinese",
  "flagCode": "cn",
  "locale": "zh-CN",
  "project": {
    "name": "司机注册"
  },
  "key": "zhCN",
  "validations": {
    "PLEASE_SELECT_CITY": "请选择城市。",
    "PLEASE_SELECT_SERVICE": "请选择服务.",
    "PLEASE_SELECT_DRIVER_TYPE": "请选择司机类型。",
    "PLEASE_INPUT_FIRSTNAME": "请输入您的名字。",
    "PLEASE_INPUT_LASTNAME": "请输入您的姓氏。",
    "PLEASE_INPUT_EMAIL": "请输入您的电子邮件。",
    "INVALID_EMAIL": "请设定有效的电子邮件。",
    "PLEASE_INPUT_MOBILE_PHONE": "请输入您的电话号码。",
    "INVALID_PHONE_NUMBER": "请设定有效的电话号码。",
    "PHONE_NUNBER_EXITS": "电话号码已被其他帐户使用。",
    "USERNAME_EXITS": "该用户名已存在于我们的系统中。",
    "ERROR_INPUT_VALID_USERNAME": "用户名只能包含小写字母和数字，长度必须在 3 到 20 个字符之间",
    "FIELD_IS_REQUIRED": "此字段是必需的。",
    "REFERRAL_CODE_INVALID": "推荐代码无效。",
    "INVALID_USERNAME": "请设定有效的用户名。",
    "FIELD_IS_REQUIRED_BETWEEN_3_TO_20": "用户名必须介于 3 到 20 个字符之间。",
    "PHONE_NUNBER_EXITS_IN_GROUP": ""
  },
  "errors": {
    "3034": "该电话已存在于系统中。 请使用其他号码。",
    "4001": "此车牌已经存在",
    "NETWORK_ERROR": "错误：网络错误"
  },
  "general": {
    "CONTINUE": "继续",
    "STEP_COMPLETE": "{0}/{1} 已完成",
    "STEP_HEADER": "步骤{0}",
    "BACK": "后退",
    "NEXT": "下一个",
    "SUBMIT": "提交",
    "Clear_Selected_Items": "清除所选项目",
    "no_results": "没有结果",
    "add_new_item": "添加"
  },
  "home": {
    "BECOME": "注册成为 %{name} 司机。",
    "HEADING": "输入您的基本信息即可开始",
    "APPLY_NOW": "注册",
    "INDIVIDUAL_CAR_GOJO": "约车出行/出租车 - 我自己的车辆",
    "INDIVIDUAL_CAR": "约车出行 - 我拥有车辆",
    "COMPANY_CAR": "约车出行 - 公司车辆",
    "DELIVERY_INDIVIDUAL_CAR": "送货-我自己的车辆",
    "DELIVERY_COMPANY_CAR": "送货-公司车辆",
    "PLACEHOVER_SELECT_PRE_CITY": "城市",
    "PLACEHOVER_SELECT_REGISTRATION_TYPE": "选择司机类型",
    "USERNAME": "用户名",
    "FIRST_NAME": "姓氏",
    "LAST_NAME": "名字",
    "EMAIL": "电子邮件 (可选)",
    "PHONE_NUMBER": "手机号码",
    "REFERRAL_CODE": "推荐码（可选）",
    "I_AGREE_TO": "注册即表示我已阅读并同意",
    "TERMS_OF_USE": "服务条款",
    "AND": "以及",
    "PRIVACY_POLICY": "隐私政策",
    "I_AGREE_TO_AFTER": ".",
    "Company": "供应商",
  },
  "finish": {
    "FINISH_1": "感谢您注册 %{name}!",
    "FINISH_3": "立即登录即可开始！",
    "FINISH_USE_PASSWORD_1": "差不多了！",
    "FINISH_USE_PASSWORD_2": "要激活您的帐户并接收工作，请按照以下步骤操作：",
    "FINISH_USE_PASSWORD_3": "1. 使用您注册的电话号码登录",
    "FINISH_USE_PASSWORD_4": "2.密码：<span style='font-weight:bold'>12345678</span>",
    "FINISH_USE_PASSWORD_5": "3. 完成您的申请",
    "OK": "登录",
    "downloadApp": "立即下载<span style='color: %{color}' >%{name}</span> 司机应用程序并使用您注册的电话号码登录。"
  },
  "datePlaceHolder": "MM/DD/YYYY"
};