import React, { Component } from 'react';
import './LoadingSpiner.css';

class LoadingSpiner extends Component {
    render() {
        if (this.props.show) {
            return (
                <div className="loading-spiner">Loading&#8230;</div>
            )
        } else {
            return null;
        }
    }
}

export default LoadingSpiner