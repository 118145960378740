export const ku = {
  "name": "Kurdish",
  "flagCode": "iq",
  "locale": "ku-IQ",
  "project": {
    "name": "شۆفێر خۆت تۆمار بکە"
  },
  "key": "ku",
  "validations": {
    "PLEASE_SELECT_CITY": "تکایە شار هەڵبژێرە",
    "PLEASE_SELECT_SERVICE": "تکایە خزمەتگوزاری هەڵبژێرە",
    "PLEASE_SELECT_DRIVER_TYPE": "تکایە جۆری شۆفێر هەڵبژێرە.",
    "PLEASE_INPUT_FIRSTNAME": "تکایە ناوی یەکەمت بنووسە",
    "PLEASE_INPUT_LASTNAME": "تکایە ناوی کۆتایی خۆت بنووسە",
    "PLEASE_INPUT_EMAIL": "تکایە ئیمەیڵەکەت بنووسە",
    "INVALID_EMAIL": "تکایە ئیمەیڵێکی دروست دیاری بکە.",
    "PLEASE_INPUT_MOBILE_PHONE": "تکایە ژمارەی تەلەفۆنەکەت بنووسە.",
    "INVALID_PHONE_NUMBER": "تکایە ژمارە تەلەفۆنێکی دروست دیاری بکە",
    "PHONE_NUNBER_EXITS": "ژمارەی تەلەفۆن لە ئێستادا لەگەڵ هەژمارێکیتر کارایە.",
    "USERNAME_EXITS": "ئەم ناوی بەکارهێنەرە پێشتر لەسیستەمەکەماندا هەیە",
    "ERROR_INPUT_VALID_USERNAME": "ناوی بەکارهێنەر ڕەنگە تەنها پیت و ژمارەی خوارەوەی تێدابێت و دەبێت درێژییەکەی لە نێوان ٣ بۆ ٢٠ پیت بێت",
    "FIELD_IS_REQUIRED": "ئەم فایڵە پێویستە",
    "REFERRAL_CODE_INVALID": "کۆدی ڕەوانەکردن نادروستە",
    "INVALID_USERNAME": "تکایە ناوی بەکارهێنەرێکی دروست دیاری بکە.",
    "FIELD_IS_REQUIRED_BETWEEN_3_TO_20": "ناوی بەکارهێنەر دەبێت لە نێوان ٣ بۆ ٢٠ پیت بێت.",
    "PHONE_NUNBER_EXITS_IN_GROUP": ""
  },
  "errors": {
    "3034": "ئەم مۆبایلە لەسەر سیستەمەکە بوونی هەبووە.  تکایە یەکێکی تر بنووسە.",
    "4001": "تابلۆی مۆڵەتەکە بوونی هەیە",
    "NETWORK_ERROR": "هەڵە: هەڵەی تۆڕ"
  },
  "general": {
    "CONTINUE": "بەردەوامبه",
    "STEP_COMPLETE": "{0}/{1} تەواو",
    "STEP_HEADER": "هەنگاوی {0}",
    "BACK": "گەڕانەوە",
    "NEXT": "دواتر",
    "SUBMIT": "پێشکەشکردن",
    "Clear_Selected_Items": "بڕگە دیاریکراوەکان بسڕەوە",
    "no_results": "هیچ ئەنجامێک نەدۆزرایەوە",
    "add_new_item": "دانەیەکی نوێ زیاد بکە"
  },
  "home": {
    "BECOME": "ببە بە %{name} شۆفێر.",
    "HEADING": "بۆ دەستپێکردن زانیارییە سەرەتاییەکانت دابنێ",
    "APPLY_NOW": "خۆت تۆمار بکە",
    "INDIVIDUAL_CAR_GOJO": "گواستنەوە/تەکسی - من خاوەنی ئۆتۆمبێلم",
    "INDIVIDUAL_CAR": "گواستنەوە - من خاوەنی ئۆتۆمبێلم",
    "COMPANY_CAR": "گواستنەوە - ئۆتۆمبێلی کۆمپانیا",
    "DELIVERY_INDIVIDUAL_CAR": "گەیاندن - من خاوەنی ئۆتۆمبێلم",
    "DELIVERY_COMPANY_CAR": "گەیاندن - ئۆتۆمبێلی کۆمپانیا",
    "PLACEHOVER_SELECT_PRE_CITY": "شار",
    "PLACEHOVER_SELECT_REGISTRATION_TYPE": "جۆری شۆفێر ديارى بكه",
    "USERNAME": "ناوی بەکارهێنەر",
    "FIRST_NAME": "ناوی یەکەم",
    "LAST_NAME": "ناوی کۆتایی",
    "EMAIL": "ئیمەیڵ",
    "PHONE_NUMBER": "ژمارە مۆبایڵ",
    "REFERRAL_CODE": "کۆدی ڕەوانەکردن (ئارەزوومەندانە)",
    "I_AGREE_TO": "لەکاتی تۆمارکردن، من خوێندومەتەوە و ڕازی بووم بە...",
    "TERMS_OF_USE": "مەرجەکانی خزمەتگوزاری",
    "AND": "وە",
    "PRIVACY_POLICY": "سیاسەتی پاراستنی نهێنی",
    "I_AGREE_TO_AFTER": ".",
    "Company": "Supplier",
  },
  "finish": {
    "FINISH_1": "سوپاس بۆ ناو تۆمارکردنت لەگەڵ %{name}!",
    "FINISH_3": "ئێستا بچۆرە ژوورەوە بۆ ئەوەی دەست پێ بکەیت!",
    "FINISH_USE_PASSWORD_1": "نزیکە لێرە",
    "FINISH_USE_PASSWORD_2": "بۆ چالاککردنی هەژمارەکەت و وەرگرتنی کار، تکایە ئەم هەنگاوانە جێبەجێ بکە:",
    "FINISH_USE_PASSWORD_3": "1. بە ژمارە تەلەفۆنە تۆمارکراوەکەت بچۆرە ژوورەوە",
    "FINISH_USE_PASSWORD_4": "2. وشەی نهێنی: <span style='font-weight: bold'>12345678</span>",
    "FINISH_USE_PASSWORD_5": "3. داواکارییەکەت پڕبکەرەوە",
    "OK": "چونه‌ ژووره‌وه‌",
    "downloadApp": "ئێستا <span style='color: %{color}' >%{name}</span> ئەپی شۆفێر دابەزێنە و بە ژمارەی تەلەفۆنە تۆمارکراوەکەت بچۆرە ژوورەوە."
  },
  "datePlaceHolder": "MM/DD/YYYY"
};