import React, { useEffect } from 'react';
import { Field, ErrorMessage } from 'formik';
import { Form } from 'react-bootstrap';
import TextError from "./TextError";
import Select from 'react-select'

const HEIGHT_SELECT_SEARCH = '39px'
const styles = {
    control: (baseStyles, state) => {
        console.log('state', state)
        return {
        ...baseStyles,
        minHeight: HEIGHT_SELECT_SEARCH,
        height: HEIGHT_SELECT_SEARCH,
        borderColor: state?.selectProps?.error ? 
            '#FF6565' 
            : state.isFocused ?
                '#01A4CE' : 
                '#CCD6DD',
        boxShadow: state.isFocused ? '0 0 1px #01A4CE' : '',
        '&:hover': {
            borderColor: '#01A4CE',
        },
    }},
    valueContainer: (provided, state) => ({
        ...provided,
        height: HEIGHT_SELECT_SEARCH,
        padding: '0px 8px',
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: '#BFBFC5',
        gridArea: '1 / 1 / 2 / 3'
    }),
    menu: (provided, state) => ({
        ...provided,
        zIndex: 1000
    }),
    input: (provided, state) => ({
        ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
        ...provided,
        height: HEIGHT_SELECT_SEARCH,
    }),
};

function SelectField(props) {
    const { label, name, values, options, touched, errors, formik, getRegistrationType, hasSearch, valueDefault, ...rest } = props;

    useEffect(() => {
        if (name === "registrationType") {
            formik.setFieldValue(name, '');
        };
    }, [options])

    const handleOnChange = (e) => {
        let value = e.target.value;
        formik.setFieldValue(name, value);
        if (name === 'preferredCity') {
            const services = getRegistrationType(value);
            if(services.length === 1) {
                formik.setFieldValue('registrationType', services[0].value);
            };
        };
    };

    if(hasSearch) {
        return (
            <Form.Group>
                <Field 
                    name={name}
                    id={name}
                    component={SelectFieldSearch} 
                    options={options} 
                    placeholder={rest.placeholder} 
                    valueDefault={valueDefault}
                />
                <ErrorMessage name={name} component={TextError} />
            </Form.Group>
        ) 
    }

    return (
        <Form.Group>
            <Field
                as='select'
                id={name}
                name={name}
                {...rest}
                className={values[name] === '' ? `select-box-placehover${touched[name] && errors[name] ? " error" : ''}` : `select-box${touched[name] && errors[name] ? " error" : ''}`}
                onChange={(e) => handleOnChange(e)}
            >
                <option value="" disabled hidden>{label}</option>
                {
                    options.map(option => {
                        return (
                            <option key={option.value} value={option.value}>
                                {option.key}
                            </option>
                        )
                    })
                }
            </Field>
            <ErrorMessage name={name} component={TextError} />
        </Form.Group>
    )
}

const SelectFieldSearch = ({
    options,
    field,
    form,
    placeholder,
    valueDefault,
}) => {

    useEffect(() => {
        if(valueDefault) {
            form.setFieldValue(field.name, valueDefault)
        }
    }, [valueDefault])

    const handleBlur = () => {
        form.setFieldTouched(field.name, true);
    };

    return (
        <>
            <Select
                options={options}
                id={field.name}
                styles={styles}
                error={form?.touched[field?.name] && form?.errors?.[field?.name] ? true : false}
                name={field.name}
                className={`selectSearch`}
                value={options ? options.find(option => option.value === field.value) : ''}
                onChange={(option) => form.setFieldValue(field.name, option.value)}
                onBlur={() => handleBlur()}
                placeholder={placeholder}
            />
        </>
    )
}

export default SelectField