import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore
} from "react-redux-i18n";

import 'bootstrap/dist/css/bootstrap.min.css';

import { translations } from "./Constants/language/languages";

import store from './Store/store';
import Home from './Containers/Home';
import './App.css';

localStorage.removeItem("driver-signup-language");
localStorage.removeItem("locale");

let locaLanguage = localStorage.getItem("driver-signup-language");

syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));
store.dispatch(setLocale(locaLanguage || "en"));

function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className='App'>
          <Route exact path={'/'} name="" component={Home} />
        </div>
      </Router>
    </Provider>
  );
}

export default App;
