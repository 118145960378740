import React from 'react';
import { ErrorMessage } from 'formik';
import { Form } from 'react-bootstrap';

import TextError from "./TextError";
import IntlTelInputApp from './IntlTelInputCustom/IntlTelInputApp';

function InputPhoneField(props) {
    const { label, name, touched, errors, formik, countryCode, ...rest } = props;

    const handlePhoneChange = (
        status,
        value,
        countryData,
        number,
        id,
        isBlur,
        isDialCodeOnly
    ) => {
        if (isBlur) {
            // console.log(" isBlur ", isBlur);
            // console.log(" status ", status);
            formik.setFieldTouched(name)
        } else {
            let correctValue = number ? number : value;
            // console.log("correctValue ",correctValue)
            formik.setFieldValue(name, correctValue);
        }
    };

    return (
        <Form.Group dir='ltr'>
            <IntlTelInputApp
                css={['intl-tel-input', 'form-control']}
                // utilsScript={'libphonenumber.js'}
                value={formik.values.phone}
                onPhoneNumberBlur={(
                    status,
                    value,
                    countryData,
                    number,
                    id,
                    suggestion,
                    isDialCodeOnly
                ) => {
                    handlePhoneChange(
                        status,
                        value,
                        countryData,
                        number,
                        id,
                        true,
                        isDialCodeOnly
                    );
                }}
                onPhoneNumberChange={(status, value, countryData, number, id) => {
                    handlePhoneChange(
                        status,
                        value,
                        countryData,
                        number,
                        id,
                        false
                    );
                }}
                defaultCountry={countryCode}
                fieldId={name}
                fieldName={name}
                {...rest}
                id={name}
                classNameInput={touched[name] && errors[name] ? "error" : null}
            />
            <ErrorMessage name={name} component={TextError} />
        </Form.Group>
    )
}

export default InputPhoneField