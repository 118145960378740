import React, { useState, useEffect } from 'react';
import { DropdownButton, Dropdown, Image, ButtonGroup } from "react-bootstrap";
import FlagIconFactory from 'react-flag-icon-css';
import _ from 'lodash';
import { setLocale } from "react-redux-i18n";
import { connect } from "react-redux";

import { translations } from "../../Constants/language/languages";
import "./header.css";

const FlagIcon = FlagIconFactory(React, { useCssModules: false });


function Header(props) {
    const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem("driver-signup-language"));
    const [locale, setLocale] = useState(localStorage.getItem("locale"));
    const [languagesList, setLanguagesList] = useState({});
    const [languageName, setLanguageName] = useState('');
    const [currentCountry, setCurrentCountry] = useState({});
    const { drvSignUpLanguages, groupBrand, fleet } = props.fleetInfo;
    useEffect(() => {
        if (translations) {
            setLanguagesList(translations);
        };
    }, []);
    useEffect(() => {
        _.forEach(translations, (language, i) => {
            if (currentLanguage === language.key) {
                setLanguageName(language.name)
            }
        });
    }, [currentLanguage]);

    useEffect(() => {
        if (props.countryFleet) {
            let dataCountryCode = currentCountry;
            dataCountryCode.countryCode = props.countryFleet;
            setCurrentCountry(dataCountryCode);
        }
    }, [props.countryFleet])


    const switchLanguage = (lang) => {
        if (lang === currentLanguage) return true;
        _.forEach(languages, function (language, i) {
            if (lang === language.key) {
                localStorage.setItem("locale", language.locale);
                setLocale(language.locale)
            }
        });
        setCurrentLanguage(lang);
        props.setLocale(lang);
        localStorage.setItem("driver-signup-language", lang);
    }

    const getLanguages = () => {
        let fleetLang;
        let languages = [];
        _.forEach(drvSignUpLanguages, function (lang, i) {
            fleetLang = lang.substring(0, 2);
            switch (lang) {
                case 'en-GB':
                    fleetLang = 'enGB';
                    break;
                case 'ur-RO':
                    fleetLang = 'urRO';
                    break;
                case 'zh-CN':
                    fleetLang = 'zhCN';
                    break;
                case 'zh-HK':
                    fleetLang = 'zhHK';
                    break;
                default:
                    break;
            };

            if (languagesList.hasOwnProperty(fleetLang)) {
                if (fleetLang !== props.fleetInfo.fleet.language && fleetLang !== 'en') {
                    function compare_name(a, b) {
                        if (a.name < b.name) {
                            return -1;
                        } else if (a.name > b.name) {
                            return 1;
                        } else {
                            return 0;
                        }
                    };

                    languages.push(languagesList[fleetLang]);
                    languages.sort(compare_name)
                };
            }
        });

        //Default EN position the second SL-19759
        languages.unshift(languagesList.en);

        // Default Fleet Language the first SL-19759
        _.forEach(drvSignUpLanguages, function (lang, i) {
            fleetLang = lang.substring(0, 2);
            if (languagesList.hasOwnProperty(fleetLang)) {
                if (fleetLang === props.fleetInfo.fleet.language && fleetLang !== 'en') {
                    languages.unshift(languagesList[fleetLang]);
                };
            }
        });

        return languages;
    };

    const languages = _.orderBy(getLanguages(), ['name']);
    const flagCode = currentLanguage && languagesList[currentLanguage] && languagesList[currentLanguage].flagCode ? languagesList[currentLanguage].flagCode : '';
    // const flatCountryCode = currentCountry && currentCountry.countryCode ? currentCountry.countryCode.toLowerCase() : '';

    return (
        <div className="header-wrapper">
            <div className='header-box'>
                <div className="header-signup">
                    <div>
                        <a href="#" className="company-logo">
                            <Image
                                className="logo"
                                src={props.fleetInfo.fleet && props.fleetInfo.fleet.providerSetting && props.fleetInfo.fleet.providerSetting.otherSetting && props.fleetInfo.fleet.providerSetting.otherSetting.logo ? props.fleetInfo.fleet.providerSetting.otherSetting.logo : props.fleetInfo.fleet ? props.fleetInfo.fleet.logo : ''}
                            />
                        </a>
                    </div>
                    {flagCode ? (
                        <div className="switch-language">
                            <DropdownButton
                                id="switch-lang-dropdown"
                                title={languageName}
                            >
                                {
                                    _.map(languages, (lang, index) => {
                                        return (
                                            <Dropdown.Item eventKey={index} key={lang.key} onClick={() => switchLanguage(lang.key)}>
                                                <FlagIcon code={lang.flagCode} className="mr-sm" /> {' '}
                                                <span>{lang.name}</span>
                                            </Dropdown.Item>
                                        )
                                    })
                                }
                            </DropdownButton>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        fleet: state.fleet,
        locale: state.i18n.locale
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLocale: (data) => dispatch(setLocale(data))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Header)