export function formatPhoneNumber(text) {
  if (text === undefined) {
    return '';
  }
  if (text === null) return '';
  if (typeof text === 'string') {
    if (text.trim().length == 0) {
      return '';
    }
  }

  return text
    .replace(/[a-zA-Z`~!@#$ %^&*()_=\\|{};:"'></?,.-]/g, '')
    .replace(/(?!^)\+/g, '');
}

export function trimPhoneNumber(phone, noPlus) {
  if (phone) {
    const newPhone = phone
      .replace(new RegExp(' ', 'g'), '')
      .replace(new RegExp('-', 'g'), '');
    if (noPlus) {
      return newPhone.replace(/\+/g, '');
    }
    return newPhone;
  }
  return '';
}

const fallbackCopyTextToClipboard = (text) => {
  var textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
};

export function copyTextToClipboard(text) {
  try {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }

    navigator.clipboard.writeText(text).then(
      function () {
        console.log('Async: Copying to clipboard was successful!');
      },
      function (err) {
        console.error('Async: Could not copy text: ', err);
      }
    );
  } catch (err) {
    console.error('Something went wrong with clipboard: ', err);
  }
}
