export const en = {
  "name": "English",
  "flagCode": "us",
  "locale": "en-US",
  "project": {
    "name": "Driver Signup"
  },
  "key": "en",
  "validations": {
    "PLEASE_SELECT_CITY": "Please select city.",
    "PLEASE_SELECT_SERVICE": "Please select service.",
    "PLEASE_SELECT_DRIVER_TYPE": "Please select driver type.",
    "PLEASE_INPUT_FIRSTNAME": "Please enter your first name.",
    "PLEASE_INPUT_LASTNAME": "Please enter your last name.",
    "PLEASE_INPUT_EMAIL": "Please enter your email.",
    "INVALID_EMAIL": "Please specify a valid email.",
    "PLEASE_INPUT_MOBILE_PHONE": "Please enter your phone number.",
    "INVALID_PHONE_NUMBER": "Please specify a valid phone number.",
    "PHONE_NUNBER_EXITS": "Phone number already in use with another account.",
    "USERNAME_EXITS": "This username already exists on our system.",
    "ERROR_INPUT_VALID_USERNAME": "Username may only contain lower letters & numbers and must be between 3 and 20 characters long",
    "FIELD_IS_REQUIRED": "This field is required.",
    "REFERRAL_CODE_INVALID": "Referral code is invalid.",
    "INVALID_USERNAME": "Please specify a valid username.",
    "FIELD_IS_REQUIRED_BETWEEN_3_TO_20": "Username must be between 3 and 20 characters.",
    "PHONE_NUNBER_EXITS_IN_GROUP": ""
  },
  "errors": {
    "3034": "This phone has been existing on the system. Please input another one.",
    "4001": "The License Plate is exits",
    "NETWORK_ERROR": "Error: Network Error"
  },
  "general": {
    "CONTINUE": "Continue",
    "STEP_COMPLETE": "{0}/{1} Complete",
    "STEP_HEADER": "Step {0}",
    "BACK": "Back",
    "NEXT": "Next",
    "SUBMIT": "Submit",
    "Clear_Selected_Items": "Clear selected items",
    "no_results": "No results",
    "add_new_item": "Add New"
  },
  "home": {
    "BECOME": "Become a %{name} Driver.",
    "HEADING": "Enter your basic information to get started",
    "APPLY_NOW": "Sign up",
    "INDIVIDUAL_CAR_GOJO": "Transport/Taxi - I own vehicle",
    "INDIVIDUAL_CAR": "Transport - I own vehicle",
    "COMPANY_CAR": "Transport - Company vehicle",
    "DELIVERY_INDIVIDUAL_CAR": "Delivery - I own vehicle",
    "DELIVERY_COMPANY_CAR": "Delivery - Company vehicle",
    "PLACEHOVER_SELECT_PRE_CITY": "City",
    "PLACEHOVER_SELECT_REGISTRATION_TYPE": "Select driver type",
    "USERNAME": "Username",
    "FIRST_NAME": "First Name",
    "LAST_NAME": "Last Name",
    "EMAIL": "Email (optional)",
    "PHONE_NUMBER": "Mobile Number",
    "REFERRAL_CODE": "Referral code (optional)",
    "I_AGREE_TO": "By registering, I've read and agreed to the",
    "TERMS_OF_USE": "Terms of Service",
    "AND": "and",
    "PRIVACY_POLICY": "Privacy Policy",
    "I_AGREE_TO_AFTER": ".",
    "Company": "Supplier",
  },
  "finish": {
    "FINISH_1": "Thank you for signing up with %{name}!",
    "FINISH_3": "Login now to get started!",
    "FINISH_USE_PASSWORD_1": "Almost there!",
    "FINISH_USE_PASSWORD_2": "To activate your account and receive jobs, please follow these steps:",
    "FINISH_USE_PASSWORD_3": "1. Login with your registered phone number",
    "FINISH_USE_PASSWORD_4": "2. Password: <span style='font-weight: bold'>12345678</span>",
    "FINISH_USE_PASSWORD_5": "3. Complete your application",
    "OK": "Login",
    "downloadApp": "Download <span style='color: %{color}' >%{name}</span> Driver app now and login with your registered phone number."
  },
  "datePlaceHolder": "MM/DD/YYYY"
};