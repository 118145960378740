import React, { useEffect, useState } from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { I18n, Translate } from "react-redux-i18n";
import DowloadApp from './dowloadApp';
import './thankYou.css';

// gojosgd://providersignup?country=VN,number=909000045
// 'https://play.google.com/store/apps/details?id=com.gojomalaysia.driver'
// 'https://apps.apple.com/us/app/id1455304266'

// Fleets is ACE
const fleetIdsACE = ["hoanglocal", "gojosg", "gojovn", "gojotexas", "gojonorway", "gojofrance", "gojoscotland", "gojowi"];
const domainSignup = ["providersignup-global", "signup", "providersignup", "providersignup-sea"];

function ThankYou(props) {
    const { mainColor, resData, appName, OSInfo, fleetName, fleetId, isSubDomain, referralID, usePassword, isIframe, fleetInfo, ctrRtl } = props;
    const [isAce, setIsAce] = useState(false);

    useEffect(() => {
        if (isSubDomain &&
            isSubDomain.enable &&
            // isSubDomain.subDomain !== 'signup' &&
            // isSubDomain.subDomain !== 'providersignup' &&
            // isSubDomain.subDomain !== 'providersignup-sea' &&
            !domainSignup.includes(isSubDomain.subDomain) &&
            fleetIdsACE.includes(fleetId)
        ) {
            setIsAce(true)
        };
        if (referralID !== '') {
            setIsAce(true)
        };
    }, [isSubDomain, referralID]);

    const sendMessage = (country, number) => {
        var data = {
            'action': 'login',
            'country': country,
            'number': number,
        }
        // iOS
        try {
            window.webkit.messageHandlers.JSBridge.postMessage(data);
            console.log('sendMessage', data)
        } catch (error) {
            console.log(error)
        };
    };

    const handleClick = () => {
        const phoneNumber = parsePhoneNumberFromString(resData.phone);
        // const phoneNumber = parsePhoneNumberFromString('+84909000045');
        const regexpAppName = appName.replace(/[^a-zA-Z0-9]+/g, '');
        if (phoneNumber) {
            const country = phoneNumber.country;
            const number = phoneNumber.nationalNumber;
            let urlDeepLink = `${regexpAppName}://providersignup?country=${country},number=${number}`;
            // let urlDeepLink = 'gojosgd://providersignup?country=VN,number=909000045';
            if (OSInfo === "Driver signup (iOS)") {
                console.log('sendMessage', "iOS")
                return sendMessage(country, number);
            };
            if (OSInfo === "Driver signup (Android)") {
                console.log('sendMessage', "Android")
                return window.location = urlDeepLink;
            };
            return window.location = urlDeepLink;
        };
    };

    let classRtl = ctrRtl ? "textRighToLeft" : "";
    return (
        <>
            {isIframe || isAce ?
                <DowloadApp
                    fleetInfo={fleetInfo}
                    mainColor={mainColor}
                    fleetName={fleetName}
                    fleetId={fleetId}
                    OSInfo={OSInfo}
                    ctrRtl={ctrRtl}
                />
                :
                <div className="completed-form">
                    <div className="box">
                        <div
                            className={'box-form ' + classRtl}
                            dir={ctrRtl ? "rtl" : "ltr"}>
                            {usePassword ?
                                <div>
                                    <h1>{I18n.t("finish.FINISH_USE_PASSWORD_1", { name: fleetName })}</h1>
                                    <p>{I18n.t("finish.FINISH_USE_PASSWORD_2")}</p>
                                    <p>{I18n.t("finish.FINISH_USE_PASSWORD_3")}</p>
                                    <p>
                                        <Translate value="finish.FINISH_USE_PASSWORD_4" dangerousHTML />
                                    </p>
                                    <p>{I18n.t("finish.FINISH_USE_PASSWORD_5")}</p>
                                </div> :
                                <div>
                                    <h1>{I18n.t("finish.FINISH_1", { name: fleetName })}</h1>
                                    <p>{I18n.t("finish.FINISH_3")}</p>
                                </div>
                            }
                            <button
                                className="completed-btn"
                                type='submit'
                                style={{ "backgroundColor": `${mainColor}` }}
                                onClick={e => handleClick()}
                            >
                                <span>{I18n.t("finish.OK")}</span>
                            </button>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default ThankYou