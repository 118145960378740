import React from 'react';
import { I18n, Translate } from "react-redux-i18n";
import android_en from '../../Constants/images/android_en.svg';
import ios_en from '../../Constants/images/ios_en.svg';
import huawei_en from '../../Constants/images/btn_huawei.svg';


export default function DowloadApp(props) {
    const { mainColor, fleetName, OSInfo, fleetInfo, ctrRtl } = props;
    let classRtl = ctrRtl ? "textRighToLeft" : "";
    return (
        <div className="completed-form">
            <div className="box">
                <div
                    className={'box-form ' + classRtl}
                    dir={ctrRtl ? "rtl" : "ltr"}>
                    <p
                        style={{
                            'color': `${mainColor}`
                        }}
                        className="fleetname-content"
                    >
                        {I18n.t("finish.FINISH_1", { name: fleetName })}
                    </p>
                    <p className="dowload_title">
                        <Translate value="finish.downloadApp" name={fleetName} color={mainColor} dangerousHTML />
                    </p>
                    <div className="qrcode_container">
                        <div className="qr_content">
                            {OSInfo === 'Driver signup (Web)' && fleetInfo.drvApp && fleetInfo.drvApp.qrcode ?
                                <div className="qrcode">
                                    <div>
                                        <img className="img_qrcode" src={fleetInfo.drvApp.qrcode} />
                                    </div>
                                </div>
                                : null}
                            <div className="dowload_calculator">

                                {OSInfo === 'Driver signup (iOS)' && fleetInfo.appStoreDrv ?
                                    <div className="bt_store">
                                        <a href={fleetInfo.appStoreDrv} className="linkApp" target="_blank">
                                            <img className="dowloadApp imgDowload dowloadAppAndroid" src={ios_en} />
                                        </a>
                                    </div>

                                    : null}
                                {OSInfo === 'Driver signup (Android)' && fleetInfo.googlePlayDrv ?
                                    <div className="bt_store">
                                        <a href={fleetInfo.googlePlayDrv} className="linkApp" target="_blank">
                                            <img className="dowloadApp imgDowload dowloadAppAndroid" src={android_en} />
                                        </a>
                                        {fleetInfo?.socialSetting?.appGalleryDrv && <a href={fleetInfo?.socialSetting?.appGalleryDrv} className="linkApp" target="_blank">
                                            <img className="dowloadApp imgDowload dowloadAppAndroid" src={huawei_en} />
                                        </a>}
                                    </div>
                                    : null}

                                {OSInfo === 'Driver signup (Web)' ?
                                    <div className="bt_store">
                                        {fleetInfo.appStoreDrv && <a href={fleetInfo.appStoreDrv} className="linkApp" target="_blank">
                                            <img className="dowloadApp imgDowload dowloadAppAndroid" src={ios_en} />
                                        </a>}
                                        {fleetInfo.googlePlayDrv && <a href={fleetInfo.googlePlayDrv} className="linkApp" target="_blank">
                                            <img className="dowloadApp imgDowload dowloadAppAndroid" src={android_en} />
                                        </a>}
                                        {fleetInfo?.socialSetting?.appGalleryDrv && <a href={fleetInfo?.socialSetting?.appGalleryDrv} className="linkApp" target="_blank">
                                            <img className="dowloadApp imgDowload dowloadAppAndroid" src={huawei_en} />
                                        </a>}
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
