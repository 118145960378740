import React from 'react';
import InputField from "./InputField";
import InputFieldName from './InputFieldName'
// import InputPhoneField from './InputPhoneField';
import InputPhoneFieldNew from './InputPhoneFieldNew';
import InputFieldUsername from './InputFieldUsername'
// import Textarea from './Textarea';
import SelectField from './SelectField';
// import Radiobutton from './RadioButton';
// import CheckboxGroup from './CheckboxGroup';
// import DatePicker from './DatePicker';
// import ChakraInput from './ChakraInput';
import Term from './Term';
import Policy from './Policy';

function FormikControl(props) {
    const { control, ...rest } = props;
    switch (control) {
        case 'input':
            return <InputField {...rest} />
        case 'inputName':
            return <InputFieldName {...rest} />
        case 'inputPhone':
            return <InputPhoneFieldNew {...rest} />
        case 'inputUsername':
            return <InputFieldUsername {...rest} />
        // case 'textarea':
        //     return <Textarea {...rest} />
        case 'select':
            return <SelectField {...rest} />
        // case 'radio':
        //     return <Radiobutton {...rest} />
        // case 'checkbox':
        //     return <CheckboxGroup {...rest} />
        // case 'date':
        //     return <DatePicker {...rest} />
        // case 'chakrainput':
        //     return <ChakraInput {...rest} />
        case 'term':
            return <Term {...rest} />
        case 'policy':
            return <Policy {...rest} />
        default: return null
    }
}

export default FormikControl