export const serverBase = process.env.REACT_APP_API_URL;
// export const getProfileFleetInfo = serverBase + '/driver-sign-up/info?fleetId=';
// export const submitDriverSignupInfo = serverBase + '/driver-sign-up/save';
// export const validPhoneNumber = serverBase + '/driver-sign-up/check-phone'
// export const validPlateNumber = serverBase + '/driver-sign-up/check-plate-number?fleetId='
// export const uploadDocument = serverBase + '/driver-sign-up/upload';
// export const validUsername = serverBase + '/driver-sign-up/check-username?fleetId=';
// export const loadMoreCarMake = serverBase + '/vehiclemake/loadmore';
// export const loadMoreCarModel = serverBase + '/vehiclemodel/loadmore';
export const getFleetInfo = serverBase + '/driver-sign-up/getFleetInfo?';
export const createDriverApi = serverBase + '/driver-sign-up/create';